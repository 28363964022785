import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import CaseStudyContentBody from "../../components/pages/CaseStudyDetails/CaseStudyContentBody"

const EnhancingAccountantClientCommunicationForAConsultancyFirm = () => {
  return (
    <Layout>
      <Seo
        title="Optimising Accountant-Client Interaction for Consultancy Firms"
        description={`Explore our case study on enhancing client communication for a consultancy firm. Learn how improved strategies led to better relationships and outcomes.`}
      />

      {/* This slug is matched with blogs in Content */}
      <CaseStudyContentBody
        slug={`enhancing-accountant-client-communication-for-a-consultancy-firm`}
      />
    </Layout>
  )
}

export default EnhancingAccountantClientCommunicationForAConsultancyFirm
